import * as React from "react";
import './App.css';
//import Axios from "axios";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Home } from './pages/Home';
import { FrameInfo } from './pages/FrameInfo';
import { About } from './pages/About';
import { Navbar } from "./Navbar";
import { Api } from "./pages/Api";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import { Morphs } from "./pages/Morphs";

function App() {
  //defualtoptions has lots of configurations
  const client = new QueryClient();
  return (
    <div className="App">
      <QueryClientProvider client={client}>
        <Router>
          <div id="content">
            <header>
              <div className="title">

              </div>
            </header>
            {/* THIS NAVBAR BELOW IS A COMPONENT. SO THE NAVBAR IS GETTING IMPORTED FROM NAVBAR.JS */}
            <Navbar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/Morphs" element={<Morphs />} />
              <Route path="/frameinfo" element={<FrameInfo />} />
              <Route path="/about" element={<About />} />
              <Route path="/api" element={<Api />} />
              <Route path="*" element={<h1>PAGE NOT FOUND</h1>} />
            </Routes>
            {/* <div>footer</div> */}
          </div>
        </Router>
      </QueryClientProvider>
    </div>
  );
}

export default App;
