import React from 'react';
// will be used once I add content
// import { useQuery } from '@tanstack/react-query';
// import { formatName } from '../formatName';
// import MyImageComponent from '../getImagePath';
// import InfiniteScroll from 'react-infinite-scroll-component';
import '../App.css';
import {Link} from "react-router-dom";

export const Morphs = () => {
    return (    
        <div className='morphpagecontent'>
            {/* // ----------------------------------------------------------------------- */}
            {/* // Once this page is ready I will put this nav in Navbar.js and export it as a component in App.js */}
            <nav class="navbar navbar-expand-lg bg-body-tertiary bg-dark" data-bs-theme="dark" >
                <div class="container-fluid">
                <Link className="navbar-brand" to="/">Karuta Market</Link>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                            <li class="nav-item">
                                <Link class="nav-link"  href="#" to="/">Frames</Link>
                            </li>
                            <li class="nav-item">
                                <Link class="nav-link active" href="#" aria-current="page" to="/Morphs">Morphs</Link>
                            </li>
                            <li class="nav-item">
                                <Link class="nav-link" href="#"  to="/About">About</Link>
                            </li>
                        </ul>
                        <form class="d-flex">
                            <button class="btn btn-outline-success" type="submit">Login</button>
                        </form>
                    </div>
                </div>
            </nav>
            {/* // ----------------------------------------------------------------------- */}

            <main id="infiniteScrollContainer">
                    {/* <InfiniteScroll
                        dataLength={visibleItems}
                        next={fetchMoreData}
                        hasMore={visibleItems < filteredData.length}
                        loader={<h4>Loading...</h4>}
                        scrollableTarget="infiniteScrollContainer"
                        className='scrollableArea'
                    >
                        {filteredData.slice(0, visibleItems).map(([name, { low, high }]) => (
                            <div key={name} className='scroll-item'>
                                <h2 id='framename'>{formatName(name)}</h2>
                                <div className="image-container">
                                    <MyImageComponent
                                    itemName={name} 
                                    />
                                </div>
                                <div className='prices-text'>
                                    <div className='low-price'>Low: 🎟️{low}</div>
                                    <div className='high-price'>High: 🎟️{high}</div>
                                </div>
                            </div>
                        ))}
                    </InfiniteScroll> */}
                </main>
        </div>
    );
    

}
