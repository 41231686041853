
// import {Link} from "react-router-dom";
export const Navbar = () => {
    return (
        <div>
            {/* <Link to="/"> Home </Link>
            <Link to="frameinfo"> Frames </Link>
            <Link to="about"> Morphs </Link> */}
        </div>
    );
};